import React, { useEffect, useContext, useState } from "react"
import { ResizedImage, Modal, AnimatedLink } from "@components/shared"
import {
  CustomerCtx,
  useActions,
  CartCtx,
  AppCtx,
  useContextState,
} from "@components/contexted"
import { GitHubApi, WPApi } from "@api"
import { AddToCart } from "../Product/common"
import { withHtml } from "@components/logic"
import GitHubLogin from "react-github-login"
import { createOrder, initPayment } from "../Checkout/common"

import {
  offer,
  offer__modal,
  offer__modal__buttons,
  offer__modal__buttons__simple,
  offer__modal__summary,
  offer__modal__wrapper,
  offer__modal__bought__wrapper,
  offer__modal__bought__wrapper__items,
  offer__modal__bought__wrapper__empty,
  offer__modal__bought__wrapper__summary,
  offer__modal__wrapper__description,
  offer__modal__wrapper__description__tags,
  offer__modal__wrapper__description__coupon,
  cart__item,
  cart__item__divider,
  offer__modal__payment,
  offer__modal__payment__item,
  offer__modal__payment__gitwrapper,
  offer__modal__payment__gitwrapper__gitlogin,
  offer__modal__payment__gitwrapper__gitlogin__label,
} from "./styles/product.module.scss"

import CrossSVG from "./icons/cross.svg"
import CardSVG from "./icons/card.svg"
import LeftSVG from "./icons/leftarrow.svg"

const onSuccessGithub = (changeValue) => (data) => {
  console.log(data)
  GitHubApi.exchangeCode(data.code).then((data) => {
    console.log("daaaata", data)
    if (data) {
      changeValue({
        note: data.login,
      })
    }
  })
}

const ContactForm = () => {
  const { changeValue } = useActions(CustomerCtx, ["changeValue"])
  const {
    first_name,
    last_name,
    email,
    note,
    address_1,
    city,
    postcode,
    country,
  } = useContextState(CustomerCtx, [
    "first_name",
    "last_name",
    "email",
    "note",
    "address_1",
    "city",
    "postcode",
    "country",
  ])
  const onFailGithub = (data) => {
    console.log("onFailGithub", data)
  }
  return (
    <div className={offer__modal__payment}>
      <h3>Płatność</h3>
      <text>Dane osobowe / Dane do wysyłki</text>
      <form
        onSubmit={(ev) => {
          ev.preventDefault()
        }}
      >
        <div className={offer__modal__payment__item}>
          <text>Twoje imie</text>
          <input
            type="text"
            placeholder={"np. Jan"}
            value={first_name}
            onChange={(ev) => {
              changeValue({ first_name: ev.target.value })
            }}
          ></input>
        </div>
        <div className={offer__modal__payment__item}>
          <text>Twoje nazwisko</text>
          <input
            type="text"
            placeholder={"np. Kowalski"}
            value={last_name}
            onChange={(ev) => {
              changeValue({ last_name: ev.target.value })
            }}
          ></input>
        </div>
        <div className={offer__modal__payment__item}>
          <text>Twój adres email</text>
          <input
            type="email"
            placeholder={"np. jan@kowalski.pl"}
            value={email}
            onChange={(ev) => {
              changeValue({ email: ev.target.value })
            }}
          ></input>
        </div>
        {note === "" ? (
          <React.Fragment>
            <p>Zaloguj się do Githuba, aby potwierdzić że jesteś programistą</p>
            <div className={offer__modal__payment__gitwrapper}>
              <GitHubLogin
                clientId="485b41b89683709f9b11"
                onSuccess={onSuccessGithub(changeValue)}
                onFailure={onFailGithub}
                buttonText={
                  <div
                    className={
                      offer__modal__payment__gitwrapper__gitlogin__label
                    }
                  >
                    <h5>Zaloguj się</h5>
                    <i className="fab fa-github"></i>
                  </div>
                }
                className={offer__modal__payment__gitwrapper__gitlogin}
                valid={true}
                redirectUri="http://gladiators-landing.page.localhost-group.com/"
              />
            </div>
          </React.Fragment>
        ) : (
          <div className={offer__modal__payment__item}>
            <text>Nick na Githubie</text>
            <input type="text" value={note} disabled={true}></input>
          </div>
        )}

        <div className={offer__modal__payment__item}>
          <text>Ulica</text>
          <input
            type="text"
            placeholder={"np. Miejska 4"}
            value={address_1}
            onChange={(ev) => {
              changeValue({ address_1: ev.target.value })
            }}
          ></input>
        </div>
        <div className={offer__modal__payment__item}>
          <text>Miasto</text>
          <input
            type="text"
            placeholder={"np. Warszawa"}
            value={city}
            onChange={(ev) => {
              changeValue({ city: ev.target.value })
            }}
          ></input>
        </div>
        <div className={offer__modal__payment__item}>
          <text>Miasto</text>
          <input
            type="text"
            placeholder={"np. 00-001"}
            value={postcode}
            onChange={(ev) => {
              changeValue({ postcode: ev.target.value })
            }}
          ></input>
        </div>
        <div className={offer__modal__payment__item}>
          <text>Kraj</text>
          <input
            type="text"
            placeholder={"np. Polska"}
            value={country}
            onChange={(ev) => {
              changeValue({ country: ev.target.value })
            }}
          ></input>
        </div>
      </form>
    </div>
  )
}

const Description = withHtml("span", "span")

const CartItem = (props) => {
  const { removeFromCart } = useActions(CartCtx, ["removeFromCart"])
  const { name, image, value, product_id } = props
  return (
    <div className={cart__item}>
      <button onClick={() => removeFromCart({ product_id })}>
        <img src={CrossSVG}></img>
      </button>
      <ResizedImage {...image} size={"150w"} />
      <h5>{name}</h5>
      <p>{value} zł</p>
    </div>
  )
}

const Tags = ({ tags }) => {
  const { nodes } = tags
  return (
    <div className={offer__modal__wrapper__description__tags}>
      {nodes.map(({ slug, name }, i) => {
        return (
          <AnimatedLink to={`/tags/${slug}/`} key={i}>
            {"#" + name}
          </AnimatedLink>
        )
      })}
    </div>
  )
}

const ProductModal = (props) => {
  const [state, setState] = useState({ currentStep: 1, coupons: [] })
  const { name, image, productTags, shortDescription, productId } = props
  const { coupon } = useContextState(CartCtx, ["coupon"])
  const { imBusy } = useContextState(AppCtx, ["imBusy"])
  const { setCoupon } = useActions(CartCtx, ["setCoupon"])
  const { items, sum } = useContextState(CartCtx, ["items", "sum"])
  //create order
  const customer = useContext(CustomerCtx)
  const cart = useContext(CartCtx)
  const { loading, loaded } = useActions(AppCtx, ["loading", "loaded"])
  const { setOrderFromApi, onOrderApiError } = useActions(CartCtx, [
    "setOrderFromApi",
    "onOrderApiError",
  ])

  useEffect(() => {
    if (state.coupons.length === 0) {
      WPApi.getAllCoupons().then((data) => {
        setState({
          ...state,
          coupons: data,
        })
      })
    }
  }, [state.coupons])

  let couponAmount = 0
  if (state.coupons) {
    if (coupon) {
      state.coupons.map((el, k) => {
        if (el.code === coupon) {
          if (el.discount_type === "fixed_product") {
            if (el.product_ids.includes(productId)) {
              couponAmount = el.amount
            }
          } else {
            if (el.product_ids.includes(productId)) {
              couponAmount = parseFloat(el.amount / 100) * sum.val
            }
          }
        }
      })
    }
  }

  return (
    <React.Fragment>
      {state.currentStep === 1 ? (
        <React.Fragment>
          <div className={offer__modal__wrapper}>
            <ResizedImage {...image} size={"600w"} />
            <div className={offer__modal__wrapper__description}>
              <h3>{name}</h3>
              <Tags tags={productTags} />
              <Description>{shortDescription}</Description>
              <AddToCart {...props} />
              <div className={offer__modal__wrapper__description__coupon}>
                <h5>Miejsce na Twój kupon:</h5>
                <input
                  type={"text"}
                  placeholder={"np. b73hsa2k56"}
                  value={coupon}
                  onChange={(ev) => {
                    setCoupon(ev.target.value)
                  }}
                ></input>
              </div>
              {couponAmount !== 0 ? (
                <h6>Wartość kuponu: {parseInt(couponAmount)} PLN</h6>
              ) : (
                <h6>&nbsp;</h6>
              )}
            </div>
          </div>
          <div className={cart__item__divider}></div>
          {items.length != 0 ? (
            <div className={offer__modal__bought__wrapper}>
              <h3>Twój koszyk</h3>
              <div className={offer__modal__bought__wrapper__items}>
                {items.map((el) => (
                  <CartItem {...el} />
                ))}
              </div>
              <div className={offer__modal__bought__wrapper__summary}>
                {couponAmount !== 0 ? (
                  <h3>Razem: {sum.val - couponAmount} zł</h3>
                ) : (
                  <h3>Razem: {sum.val} zł</h3>
                )}
                <button onClick={() => setState({ ...state, currentStep: 2 })}>
                  <text>Przejdź do płatności</text>
                  <img src={CardSVG}></img>
                </button>
              </div>
            </div>
          ) : (
            <div className={offer__modal__bought__wrapper__empty}>
              <h3>Twój koszyk jest pusty.</h3>
            </div>
          )}
        </React.Fragment>
      ) : (
        <React.Fragment>
          <ContactForm />
          <div className={offer__modal__summary}>
            {couponAmount !== 0 ? (
              <h3>Razem: {sum.val - couponAmount} zł</h3>
            ) : (
              <h3>Razem: {sum.val} zł</h3>
            )}
          </div>
          <div className={offer__modal__buttons}>
            {state.paymentPhase ? (
              <div className={offer__modal__buttons__simple}>
                <button
                  disabled={imBusy}
                  onClick={() => {
                    initPayment({ cart, initLoading: loading })
                      .then((payment) => {
                        loaded()
                        window.location.href = payment.redirectUri
                      })
                      .catch((err) => {
                        console.log(err)
                      })
                  }}
                >
                  {imBusy ? (
                    <text>Procesowanie...</text>
                  ) : (
                    <text>Opłać zamówienie</text>
                  )}
                </button>
              </div>
            ) : (
              <React.Fragment>
                <button onClick={() => setState({ ...state, currentStep: 1 })}>
                  <img src={LeftSVG}></img>
                  <text>Wróć do koszyka</text>
                </button>
                <button
                  onClick={() => {
                    createOrder({
                      cart: cart.state,
                      customer: customer.state,
                      initLoading: loading,
                    })
                      .then((data) => {
                        loaded()
                        setOrderFromApi(data)
                      })
                      .catch(({ data }) => {
                        loaded()
                        onOrderApiError(data)
                      })
                    setState({
                      ...state,
                      paymentPhase: true,
                    })
                  }}
                >
                  <text>Potwierdź zamówienie</text>
                  <img src={CardSVG}></img>
                </button>
              </React.Fragment>
            )}
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

export default ProductModal
