import React from "react"
import { connect } from "react-redux"
import { customerActions, cartActions } from "@stores"

import { ProductsSummary, DiscountAndContinue, ErrorMessage } from ".."

const CartSummaryStep = (props) => {
  const { cart, changeProductQty, removeFromCart, setCoupon, stepper } = props
  console.log("CartSummaryStep", props)
  return (
    <div title={"1. Podsumowanie"}>
      <ProductsSummary
        cart={cart}
        changeProductQty={changeProductQty}
        removeFromCart={removeFromCart}
      />
      <ErrorMessage cart={cart} />
      <DiscountAndContinue
        sum={cart.sum}
        shipping={cart.shipping}
        nextStep={() => {
          stepper.current.setIndex(1, true)
        }}
        coupon={cart.coupon}
        onCouponChange={setCoupon}
      />
    </div>
  )
}

export default CartSummaryStep

// const mapStateToProps = (state) => {
//   return { cart: state.Cart }
// }

// const mapActionToDispatch = { ...cartActions }

// export default connect(mapStateToProps, mapActionToDispatch)(CartSummaryStep)
