import React, { Component, useContext } from "react"
import { AnimatedLink, AnimatedButton } from "@components/shared"
import { QuantityChanger } from "@components/shared"
import { CartCtx, useContextState } from "@components/contexted"

import {
  product__price,
  product__btns,
  product__btn,
  product__btnAlt,
  product__addtocart,
  product__prices,
} from "../styles/product.module.scss"

const cleanPrice = (price) => {
  return parseFloat(price.replace(/[$,£]+/g, ""))
}

const Prices = ({ sale, regularValue, value, unit }) => {
  return (
    <div className={product__price}>
      {sale && (
        <span>
          {regularValue}
          {unit}
        </span>
      )}
      {sale && (
        <small>
          {value}
          {unit}
        </small>
      )}
      {!sale && (
        <small>
          {value}
          {unit}
        </small>
      )}
    </div>
  )
}

// Tak wygląda używanie dispatcha
// budujemy małe komponenty i w nich używamy context
const AddToCartButton = ({ product, onInit }) => {
  const { dispatch } = useContext(CartCtx)
  const { items, sum, unit } = useContextState(CartCtx, ["items"])

  const isInCart = items.filter((el) => el.slug === product.slug)
  let isInCartBool = isInCart.length > 0
  return (
    <AnimatedButton
      className={product__btn}
      onClick={() => {
        console.log("dodaje produkt", product)
        if (isInCartBool) {
          console.log("Produkt juz jest w koszyku")
        } else {
          dispatch({
            type: "addToCart",
            payload: product,
          })
        }
        onInit()
      }}
    >
      <text>
        Dodaj do koszyka
        <i class="fas fa-shopping-cart"></i>
      </text>
    </AnimatedButton>
  )
}
// end

class AddToCart extends Component {
  state = {
    value: null,
    regularValue: null,
    quantity: 1,
    product_id: null,
    variation_id: null,
    variant: null,
    sale: false,
    price: "",
    regularPrice: "",
    unit: "PLN",
    name: "",
    slug: "",
    image: null,
  }

  changeQty = (val) => {
    const _number = Number(val)
    if (_number <= 0 || Number.isNaN(_number)) {
      return false
    } else {
      this.setState({
        quantity: _number,
        value: _number * this.state.price,
        regularValue: _number * this.state.regularPrice,
      })
    }
  }

  onInit = () => {
    if (this.props.salePrice) {
      this.setState({
        product_id: this.props.productId,
        // variation_id:this.props.variationId,
        name: this.props.name,
        slug: this.props.slug,
        sale: true,
        price: cleanPrice(this.props.salePrice),
        regularPrice: cleanPrice(this.props.regularPrice),
        regularValue: cleanPrice(this.props.regularPrice),
        value: cleanPrice(this.props.salePrice),
        quantity: 1,
        image: this.props.image,
      })
    } else {
      this.setState({
        product_id: this.props.productId,
        // variation_id:this.props.variationId,
        name: this.props.name,
        slug: this.props.slug,
        price: cleanPrice(this.props.regularPrice),
        regularPrice: cleanPrice(this.props.regularPrice),
        regularValue: cleanPrice(this.props.regularPrice),
        value: cleanPrice(this.props.regularPrice),
        quantity: 1,
        image: this.props.image,
      })
    }
  }

  componentDidMount() {
    this.onInit()
  }

  render() {
    const { quantity, value, regularValue, sale, unit } = this.state
    return (
      <div className={product__addtocart}>
        <div className={product__prices}>
          {/* <QuantityChanger onChange={this.changeQty} quantity={quantity} /> */}
          <Prices {...{ value, regularValue, sale, unit }} />
        </div>

        <div className={product__btns}>
          {/* użycie */}
          <AddToCartButton product={this.state} onInit={this.onInit} />
          {/* <AnimatedLink
            className={`${product__btn} ${product__btnAlt}`}
            to="/shop/checkout/"
          >
            <i class="fas fa-credit-card"></i>
            <i class="fas fa-angle-double-right"></i>
          </AnimatedLink> */}
        </div>
      </div>
    )
  }
}

export default AddToCart

{
  /* <AnimatedButton
            className={product__btn}
            onClick={() => {
              this.props.onAddToCart(this.state)
              this.onInit()
            }}
          >
            <i className="icofont-plus"></i>
            <i class="fas fa-shopping-cart"></i>
          </AnimatedButton> */
}
