import React from "react"
import Divider from "../../../components.refactored/standard/Home/icons/leaves.svg"
import {
  summary,
  summary__button,
  summary__error,
  circle,
} from "./styles/summary.module.scss"
import { AnimatedLink } from "@components/shared"

const PurchaseCompleted = ({ orderId }) => {
  return (
    <div className={summary}>
      <div className={summary__error}>
        <h3>Dziękujemy za zakupy!</h3>
        <h3>Niedługo zaczniemy przygotowywać Twoją przesyłkę.</h3>
      </div>
      <img src={Divider} />
      <AnimatedLink className={summary__button} to="/">
        Wróć do sklepu
      </AnimatedLink>
      <div className={circle} />
    </div>
  )
}
export default PurchaseCompleted
