import React, { useEffect, useState } from "react"
import { AnimatedButton, RadioButtons } from "@components/shared"
import {
  content__modal__wrapper,
  content__modal__wrapper__title,
  content__modal__wrapper__title__text,
  content__modal__wrapper__title__open,
  content__modal__wrapper__title__close,
  content__modal__wrapper__inner,
  margin,
} from "../../../standard/Home/style/paymentmodal.module.scss"
import { CartCtx, useActions, useContextState } from "@components/contexted"
import Right from "../../../standard/Home/icons/Animated/right.svg"
const Delivery = ({ methods }) => {
  const [showAddress, setShowAddress] = useState(false)
  const { shippings, shipping } = useContextState(CartCtx, [
    "shippings",
    "shipping",
  ])

  const { setShipping } = useActions(CartCtx, ["setShipping"])

  useEffect(() => {
    return () => {
      setShipping({})
    }
  }, [])
  return (
    <div className={content__modal__wrapper}>
      <div
        className={
          showAddress
            ? `${content__modal__wrapper__title} ${margin}`
            : content__modal__wrapper__title
        }
      >
        <div className={content__modal__wrapper__title__text}>
          Sposób dostawy?
        </div>

        <AnimatedButton
          className={
            showAddress
              ? content__modal__wrapper__title__open
              : content__modal__wrapper__title__close
          }
          onClick={() => setShowAddress((prev) => !prev)}
        >
          <img src={Right} alt="" />
        </AnimatedButton>
      </div>
      {showAddress && (
        <div className={content__modal__wrapper__inner}>
          <RadioButtons
            items={shippings}
            itemMapper={(el) => ({
              name: "shipping",
              onChange: () => {
                setShipping(el)
              },
              value: el,
              checked: el.method_title == shipping.method_title,
              name: `${el.method_title} - ${el.total} PLN`,
            })}
          />
        </div>
      )}
    </div>
  )
}

export default Delivery
