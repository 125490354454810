import React from "react"
import Divider from "../../../components.refactored/standard/Home/icons/leaves.svg"
import {
  summary,
  summary__button,
  summary__error,
  circle,
  summary__divider,
  summary__divider__mirror,
} from "./styles/summary.module.scss"
import { AnimatedLink } from "@components/shared"

const PurchaseFailed = ({ orderId, msg }) => {
  return (
    <div className={summary}>
      <div className={summary__error}>
        <h3>Ups!</h3>
        <h3>Coś poszło nie tak z płatnością.</h3>
      </div>
      <img src={Divider} />
      <AnimatedLink className={summary__button} to="/">
        Wróć do sklepu
      </AnimatedLink>
      <div className={circle} />
    </div>
  )
}
export default PurchaseFailed
